import(/* webpackMode: "eager", webpackExports: ["AccountMenu"] */ "/app/apps/diamond-chest/src/components/AccountMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieBanner"] */ "/app/apps/diamond-chest/src/components/CookieBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastWrapper"] */ "/app/apps/diamond-chest/src/components/feedback/ToastWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageSelector"] */ "/app/apps/diamond-chest/src/components/LanguageSelector.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/i18n/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeatureFlagsProvider"] */ "/app/apps/diamond-chest/src/providers/feature-flags-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionLogoutHandler"] */ "/app/apps/diamond-chest/src/providers/SessionLogoutHandler.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/app/node_modules/.pnpm/next-auth@5.0.0-beta.25_next@15.1.6_babel-plugin-react-compiler@19.0.0-beta-714736e-202_dc6089ec444608c64bdd7352489baa02/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.26.3_next@15.1.6_babel-plugin-react-compiler@19.0.0-beta-714736e-20250131_r_e3d552ce919078ab772b3a7b1b87f109/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.26.3_next@15.1.6_babel-plugin-react-compiler@19.0.0-beta-714736e-20250131_r_e3d552ce919078ab772b3a7b1b87f109/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.6_babel-plugin-react-compiler@19.0.0-beta-714736e-20250131_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../TeleNeoVariable.woff2\",\"display\":\"swap\",\"variable\":\"--font-teleneo\"}],\"variableName\":\"TeleNeo\"}");
