'use client';

import { Locale } from '@/i18n';
import { Link, usePathname } from '@/i18n/routing';
import { useLocale } from 'next-intl';
import { TbLanguage } from 'react-icons/tb';

interface LocaleItem {
  locale: Locale;
  long: string;
}

export const LanguageSelector = () => {
  const locale = useLocale();
  const pathname = usePathname();
  const LANGUAGES: LocaleItem[] = [
    { locale: 'en', long: 'English' },
    { locale: 'de', long: 'Deutsch' },
  ];
  return (
    <div key="languages" className="dropdown dropdown-end dropdown-hover">
      <div tabIndex={0} role="button" className="btn btn-ghost rounded-btn">
        <TbLanguage size={20} aria-label="Language" />
        <span className="max-md:hidden">{locale.toUpperCase() || 'EN'}</span>
      </div>
      <ul
        tabIndex={0}
        className="menu dropdown-content rounded-box bg-base-100 dark:bg-base-200 text-base-content z-2 min-w-48 p-2 shadow-sm"
      >
        {LANGUAGES.map((item) => (
          <li key={item.locale}>
            <Link
              href={pathname}
              locale={item.locale}
              className={`${item.locale === locale ? 'menu-active' : ''}`}
            >
              {item.long}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
